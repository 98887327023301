import { useForm } from 'react-hook-form';
import { upsert } from '../../../services/enderecos';
import './ModalEnderecos.css';

export default function ModalEnderecos(props) {

    const { register, handleSubmit, errors } = useForm();

    const cancelar = () => {
        props.close();
    }

    const save = (data) => {
        if (props.value) {
            editData(data);
        } else {
            addData(data);
        }
    };

    const addData = async data => {
        data["cliente"] = props.clienteId;
        const res = await upsert(data);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    const editData = async data => {
        data["cliente"] = props.value.cliente;
        const res = await upsert(data, props.value._id);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    return (
        <div className="ModalEnderecos">
            <div className="container-modal">
                <form className='form-modal' onSubmit={handleSubmit(save)}>
                    <div className="title-modal Font-large">
                        Insira os dados de endereço
                    </div>
                    <div className="fields-modal">
                        <div className="field-modal">
                            <label htmlFor="endereco">Endereço</label>
                            <input className={errors.endereco && "erroCampo"} type="text" name="endereco" defaultValue={props.value?.endereco} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="numero">Número</label>
                            <input className={errors.numero && "erroCampo"} type="text" name="numero" defaultValue={props.value?.numero} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="complemento">Complemento</label>
                            <input className={errors.complemento && "erroCampo"} type="text" name="complemento" defaultValue={props.value?.complemento} ref={register()} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="bairro">Bairro</label>
                            <input className={errors.bairro && "erroCampo"} type="text" name="bairro" defaultValue={props.value?.bairro} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="cidade">Cidade</label>
                            <input className={errors.cidade && "erroCampo"} type="text" name="cidade" defaultValue={props.value?.cidade} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="estado">Estado</label>
                            <input className={errors.estado && "erroCampo"} type="text" name="estado" defaultValue={props.value?.estado} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="cep">CEP</label>
                            <input type="text" name="cep" defaultValue={props.value?.cep} ref={register()} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="descricao">Descrição</label>
                            <input type="text" name="descricao" defaultValue={props.value?.descricao} ref={register()} />
                        </div>
                    </div>
                    <div className="buttons-modal">
                        <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                        <button className="Btn Btn-cancel" onClick={cancelar}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}