import { useCallback, useEffect, useState } from "react";
import { carregaGruposAtivos } from "../../../services/grupos";
import './GroupSelect.css';

export default function GroupSelect(props) {

    const [grupo, setGrupo] = useState([]);

    const carregarGrupo = useCallback(async () => {
        const res = await carregaGruposAtivos();
        setGrupo(res)
    }, []);

    useEffect(() => {
        carregarGrupo();
    }, [carregarGrupo]);

    const renderOptions = () => {
        return grupo.map((ele, index) => {
            return (
                <option key={index} value={ele._id}>{ele.nome}</option>
            )
        })
    }

    return (
        <div className="GroupSelect">
            <select value={props.value} name="grupo" onChange={props.onChange} ref={props.reference({ required: true })}>
                {renderOptions()}
            </select>
        </div>
    )
}