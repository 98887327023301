import axios from 'axios';
import { API_URL } from '../config/constants';
const route = 'permissao-grupo';

const carregaModulos = async () => {
    const res = await axios.get(`${API_URL}/${route}/`);
    return res.data;
}

const carregaModuloById = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/`+id);
    return res.data;
}

const carregaPermissaoPorGrupo = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/byGrupo/` + id);
    return res.data;
}

const upsert = async (obj, id) => {
    let res;
    if (id) {
        res = await axios.put(`${API_URL}/${route}/`+id, obj);
    } else {
        res = await axios.post(`${API_URL}/${route}/`, obj);
    }
    return res.data;
}

export { carregaModulos, upsert, carregaModuloById, carregaPermissaoPorGrupo };