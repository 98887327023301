import { faEdit, faLock, faLockOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router";
import { carregaGrupos, updateActive, upsert, carregaGruposComFiltro } from '../../../../services/grupos';
import { hasPermission } from "../../../../utils/utils";
import "./GruposCrud.css";

export default function GruposCrud() {

    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const [grupos, setGrupos] = useState([]);

    const carregarGrupos = useCallback(async () => {
        const res = await carregaGrupos();
        setGrupos(res);
    }, []);

    useEffect(() => {
        carregarGrupos();
    }, [carregarGrupos]);

    const editElement = (id) => {
        history.push(`/grupos/editar/${id}`);
    };

    const changeActive = async (id) => {
        await updateActive(id);
        carregarGrupos();
    };

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            const res = await upsert(obj, id);
            if(res.erro){
                alert(res.msg)
            }
        }
        carregarGrupos();
    };

    const onSubmit = obj => {
        const novoObj = Object.values(obj).toString()
        save(novoObj);
    };

    const save = async (obj) => {
        const res = await carregaGruposComFiltro(obj);
        setGrupos(res)
    };

    const renderItens = () => {
        if (grupos.length > 0) {
            return grupos.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.nome}</td>
                        <td>{ele.active === true ? "Ativo" : "Inativo"}</td>
                        <td className="actions">
                            {hasPermission('grupos', 'editar') && <button onClick={() => { editElement(ele._id) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('grupos', 'editar') && <button onClick={() => { changeActive(ele._id) }}><FontAwesomeIcon icon={ele.active === true ? faLock : faLockOpen} /></button>}
                            {hasPermission('grupos', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    return (
        <div className="GruposCrud">
            <div className="row">
                <div className="search">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="text" name="search" ref={register()} />
                        <button className="Btn Btn-confirm" type="submit">Pesquisar</button>
                        <button className="Btn Btn-confirm" type="button" onClick={()=>{carregarGrupos()}}>Limpar filtro</button>
                    </form>
                </div>
                <div className="add">
                    {hasPermission('grupos', 'criar') && <button className="Btn Btn-confirm" onClick={() => history.push(`grupos/adicionar`)}>Adicionar</button>}
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Nome</td>
                            <td>Status</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}