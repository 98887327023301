import { useHistory } from 'react-router-dom';
import ClientesCrud from "../../components/Crud/MainCrud/ClientesCrud/ClientesCrud";
import Exit from "../../components/Button/Exit/Exit";
import MenuBar from "../../components/Menu/MenuBar";
import Title from "../../components/Title/Title";
import { hasPermission } from "../../utils/utils";

import "./Clientes.css";

export default function Clientes() {

    const history = useHistory();

    if (!hasPermission('clientes')) {
        history.push('/');
    }

    return (
        <div className='Clientes'>
            <MenuBar />
            <Title>Clientes</Title>
            <div className="Container">
                <div className="Content">
                    <div className="row">
                        <ClientesCrud />
                    </div>
                    <div className="Row-exit">
                        <Exit />
                    </div>
                </div>
            </div>
        </div>
    )
};