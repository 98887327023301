import { Switch, Route, Redirect } from 'react-router';

import PrivateRoute from './PrivateRoute';

import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
// import Recovery from '../pages/Recovery/Recovery';

import Usuarios from '../pages/Usuarios/Usuarios';
import Grupos from '../pages/Grupos/Grupos';
import Clientes from '../pages/Clientes/Clientes';
import Cobrancas from '../pages/Cobrancas/Cobrancas';

import UsuariosForm from '../pages/Usuarios/UsuariosForm';
import GruposForm from '../pages/Grupos/GruposForm';
import ClientesForm from '../pages/Clientes/ClientesForm';

export default function Routes(props) {
    return (
        <Switch>
            <Route path="/login" component={Login}></Route>
            {/* <Route path="/recuperar-senha" component={Recovery}></Route> */}

            <PrivateRoute exact path="/" component={Home}></PrivateRoute>

            <PrivateRoute exact path="/usuarios" component={Usuarios}></PrivateRoute>
            <PrivateRoute path="/usuarios/adicionar" component={UsuariosForm}></PrivateRoute>
            <PrivateRoute path="/usuarios/editar/:id" component={UsuariosForm}></PrivateRoute>
            
            <PrivateRoute exact path="/grupos" component={Grupos}></PrivateRoute>
            <PrivateRoute path="/grupos/adicionar" component={GruposForm}></PrivateRoute>
            <PrivateRoute path="/grupos/editar/:id" component={GruposForm}></PrivateRoute>
            
            <PrivateRoute exact path="/clientes" component={Clientes}></PrivateRoute>
            <PrivateRoute path="/clientes/adicionar" component={ClientesForm}></PrivateRoute>
            <PrivateRoute path="/clientes/editar/:id" component={ClientesForm}></PrivateRoute>

            <PrivateRoute exact path="/cobrancas" component={Cobrancas}></PrivateRoute>

            <Redirect from="*" to="/"></Redirect>
        </Switch>
    )
}