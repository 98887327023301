const converteDataToBrasil = (data) => {
    if (!data) {
        return data
    };
    let arr1 = data.split('T');
    let arr2 = arr1[0].split('-');
    let dia = arr2[2];
    let mes = arr2[1];
    let ano = arr2[0];
    let dataFormatada = dia + '/' + mes + '/' + ano;

    return dataFormatada;
}

const converteDataToJavascript = (data) => {
    if (!data) {
        return data;
    };
    const dataFormatada = data.split('/').reverse();
    let year = dataFormatada.shift();
    dataFormatada.push(year);
    const dataJavascript = new Date(dataFormatada);
    return dataJavascript;
}

const converteValorToBrasil = (data) => {
    if (!data) {
        return data;
    };
    const valorFormatado = data.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    return valorFormatado;
}

const converteValorToJavascript = (data) => {
    if (!data) {
        return data;
    }
    const valorFormatado = data.replace('.','').replace(',','.');
    return valorFormatado;
}

export { converteDataToBrasil, converteDataToJavascript, converteValorToBrasil, converteValorToJavascript };