import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";
import ModalEnderecos from '../../../Modal/ModalEnderecos/ModalEnderecos';
import { upsert } from '../../../../services/enderecos';

import "./EnderecosCrud.css";
import { hasPermission } from '../../../../utils/utils';

export default function EnderecosCrud(props) {

    const [showModal, setShowModal] = useState(false);
    const [enderecos, setEnderecos] = useState({});

    const editElement = (ele) => {
        setEnderecos(ele)
        openModal();
    };

    const openModal = () => {
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        props.refresh(props.clienteId);
    };

    const renderItens = () => {
        if (props.dados.length > 0) {
            return props.dados.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.endereco}</td>
                        <td>{ele.numero}</td>
                        <td>{ele.complemento}</td>
                        <td>{ele.bairro}</td>
                        <td>{ele.cidade}</td>
                        <td>{ele.cep}</td>
                        <td className="actions">
                            {hasPermission('clientes', 'editar') && <button onClick={() => { editElement(ele) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('clientes', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    };

    return (
        <div className="EnderecosCrud">
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Endereço</td>
                            <td>Número</td>
                            <td>Complemento</td>
                            <td>Bairro</td>
                            <td>Cidade</td>
                            <td>CEP</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
                {showModal && <ModalEnderecos close={closeModal} clienteId={props.clienteId} value={enderecos} refresh={props.refresh} />}
            </div>
        </div>
    )
};