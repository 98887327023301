import axios from 'axios';
import { converteDataToBrasil, converteValorToBrasil, converteValorToJavascript } from '../utils/converts';
import { API_URL } from './../config/constants';
const route = 'cobrancas';

const carregaCobrancas = async () => {
    const res = await axios.get(`${API_URL}/${route}/`);
    return res.data;
}

const carregaCobrancaById = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/` + id);

    const novaData = converteDataToBrasil(res.data.dataInicio);
    const novoValor = converteValorToBrasil(res.data.valorTotal)
    const novoProxPagamento = converteDataToBrasil(res.data.proximoPagamento)
    res.data["dataInicio"] = novaData;
    res.data["valorTotal"] = novoValor;
    res.data["proximoPagamento"] = novoProxPagamento;

    return res.data;
}

const carregaCobrancasPorCliente = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/byCliente/` + id);
    return res.data;
}

const updatePayment = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/update-payment/` + id);
    return res.data;
}

const carregaCobrancasComFiltro = async (filter) => {
    const res = await axios.get(`${API_URL}/${route}?tipoServico=${filter.tipoServico}&proximoPagamento=${filter.proximoPagamento}`);
    return res.data;
}

const carregaCobrancasFiltroFixo = async (filter) => {
    const res = await axios.get(`${API_URL}/${route}?proximoPagamento=${filter}`);
    return res.data;
}

const upsert = async (obj, id) => {
    let res;
    if(obj.valorTotal){
        const valorTotal = obj.valorTotal;
        const valorJavascript = converteValorToJavascript(valorTotal);
        obj["valorTotal"] = valorJavascript;
    }
    if (id) {
        res = await axios.put(`${API_URL}/${route}/` + id, obj);
    } else {
        res = await axios.post(`${API_URL}/${route}/`, obj);
    }
    return res.data;
}

export { carregaCobrancas, upsert, carregaCobrancaById, carregaCobrancasPorCliente, updatePayment, carregaCobrancasComFiltro, carregaCobrancasFiltroFixo };