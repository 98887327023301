import { useForm } from 'react-hook-form';
import { upsert } from '../../../services/contatos';
import './ModalContatos.css';

export default function ModalContatos(props) {

    const { register, handleSubmit, errors } = useForm();

    const cancelar = () => {
        props.close();
    }

    const save = (data) => {
        if (props.value) {
            editData(data);
        } else {
            addData(data);
        }
    };

    const addData = async data => {
        data["cliente"] = props.clienteId;
        const res = await upsert(data);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    const editData = async data => {
        data["cliente"] = props.value.cliente;
        const res = await upsert(data, props.value._id);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    return (
        <div className="ModalContatos">
            <div className="container-modal">
                <form className='form-modal' onSubmit={handleSubmit(save)}>
                    <div className="title-modal Font-large">
                        Insira os dados de contato
                    </div>
                    <div className="fields-modal">
                        <div className="field-modal">
                            <label htmlFor="nome">Nome</label>
                            <input className={errors.nome && "erroCampo"} type="text" name="nome" defaultValue={props.value?.nome} ref={register({ required: true })} />
                        </div>

                        <div className="field-modal">
                            <label htmlFor="cargo">Cargo</label>
                            <input type="text" name="cargo" defaultValue={props.value?.cargo} ref={register()} />
                        </div>

                        <div className="field-modal">
                            <label htmlFor="telefone">Telefone</label>
                            <input type="text" name="telefone" defaultValue={props.value?.telefone} ref={register()} />
                        </div>

                        <div className="field-modal">
                            <label htmlFor="email">E-mail</label>
                            <input type="text" name="email" defaultValue={props.value?.email} ref={register()} />
                        </div>

                        <div className="field-modal">
                            <label htmlFor="descricao">Descrição</label>
                            <input type="text" name="descricao" defaultValue={props.value?.descricao} ref={register()} />
                        </div>

                        <div className="field-modal">
                            <label htmlFor="contatoCobranca">Contato de cobrança</label>
                            <input type="checkbox" name="contatoCobranca" defaultChecked={props.value?.contatoCobranca} ref={register()} />
                        </div>
                    </div>
                    <div className="buttons-modal">
                        <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                        <button className="Btn Btn-cancel" onClick={cancelar}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}