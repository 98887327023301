import React from 'react';
import { Redirect, Route } from 'react-router';
import { decodeToken, getToken } from './../services/auth';

const PrivateRoute = ({ component: Component, ...rest}) => {
    const token = getToken();
    const decoded = decodeToken(token);

    return (
        <Route {...rest} render={props => {
            
            if (decoded){
                return <Component {...props} />;
            } else {
                return <Redirect to="/login" />
            }
        }} />
    );
}

export default PrivateRoute;