import axios from 'axios';
import { API_URL } from './../config/constants';
const route = 'clientes';

const updateActive = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/update-status/`+id);
    return res.data;
}

const carregaClientes = async () => {
    const res = await axios.get(`${API_URL}/${route}/`);
    return res.data;
}

const carregaClienteById = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/`+id);
    return res.data;
}

const carregaClientesComFiltro = async (filter) => {
    const res = await axios.get(`${API_URL}/${route}?search=${filter}`);
    return res.data;
}

const upsert = async (obj, id) => {
    let res;
    if (id) {
        res = await axios.put(`${API_URL}/${route}/`+id, obj);
    } else {
        res = await axios.post(`${API_URL}/${route}/`, obj);
    }
    return res.data;
}

export { carregaClientes, upsert, updateActive, carregaClienteById, carregaClientesComFiltro };