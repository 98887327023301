import "./App.css";
import "./Global.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
