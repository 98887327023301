import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";
import ModalDominios from '../../../Modal/ModalDominios/ModalDominios';
import { upsert } from '../../../../services/dominios';

import "./DominiosCrud.css";
import { hasPermission } from '../../../../utils/utils';

export default function DominiosCrud(props) {

    const [showModal, setShowModal] = useState(false);
    const [dominios, setDominios] = useState({});

    const editElement = (ele) => {
        setDominios(ele)
        openModal();
    };

    const openModal = () => {
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        props.refresh(props.clienteId);
    };

    const renderItens = () => {
        if (props.dados.length > 0) {
            return props.dados.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.descricao}</td>
                        <td>{ele.login}</td>
                        <td>{ele.senha}</td>
                        <td>{ele.observacao}</td>
                        <td className="actions">
                            {hasPermission('clientes', 'editar') && <button onClick={() => { editElement(ele) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('clientes', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    };

    return (
        <div className="DominiosCrud">
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Descrição</td>
                            <td>Login</td>
                            <td>Senha</td>
                            <td>Observação</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
                {showModal && <ModalDominios close={closeModal} clienteId={props.clienteId} value={dominios} refresh={props.refresh} />}
            </div>
        </div>
    )
};