import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import MenuBar from '../../components/Menu/MenuBar';
import GroupSelect from '../../components/Select/GroupSelect/GroupSelect';
import Title from '../../components/Title/Title';
import { carregaUsuarioById, upsert } from '../../services/usuarios';
import { hasPermission } from '../../utils/utils';
import './UsuariosForm.css';

export default function UsuariosForm() {

    const params = useParams();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [usuarios, setUsuarios] = useState({});

    if(!hasPermission('usuarios','editar')){
        history.push('/');
    }

    const carregarUsuarios = useCallback(async (id) => {
        const res = await carregaUsuarioById(id);
        setUsuarios(res);
    }, []);

    useEffect(() => {
        if (params.id) {
            carregarUsuarios(params.id);
        }
    }, [carregarUsuarios, params.id]);

    const onChangeGrupo = (e) => {
        const _usuarios = { ...usuarios };
        _usuarios.grupo = e.target.value;
        setUsuarios(_usuarios);
    }

    const onSubmit = obj => {
        save(obj, usuarios._id);
    };

    const save = async (obj, id) => {
        const res = await upsert(obj, id);
        if (!res.erro) {
            history.push(`/usuarios`);
        } else {
            alert(res.msg);
        }
    };

    if (params.id) {
        return (
            <div className='UsuariosForm'>
                <MenuBar />
                <Title>Editar Usuários</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do usuário
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="nome">Nome</label>
                                        <input className={errors.nome && "erroCampo"} type="text" placeholder="Nome" name="nome" defaultValue={usuarios.nome} ref={register({ required: true })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="login">Login</label>
                                        <input className={errors.login && "erroCampo"} type="text" placeholder="Login" name="login" defaultValue={usuarios.login} ref={register({ required: true })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="senha">Senha</label>
                                        <input type="password" placeholder="(inalterada)" name="senha" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="grupo">Grupo</label>
                                        <GroupSelect reference={register} value={usuarios.grupo} onChange={onChangeGrupo} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/usuarios`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='UsuariosForm'>
                <MenuBar />
                <Title>Adicionar usuários</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do usuário
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="nome">Nome</label>
                                        <input className={errors.nome && "erroCampo"} type="text" name="nome" ref={register({ required: true })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="login">Login</label>
                                        <input className={errors.login && "erroCampo"} type="text" name="login" ref={register({ required: true })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="senha">Senha</label>
                                        <input className={errors.senha && "erroCampo"} type="password" name="senha" ref={register({ required: true })} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="">Grupo</label>
                                        <GroupSelect reference={register} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/usuarios`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};