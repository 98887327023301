import axios from 'axios';
import { API_URL } from './../config/constants';
const route = 'dominios';

const carregaDominios = async () => {
    const res = await axios.get(`${API_URL}/${route}/`);
    return res.data;
}

const carregaDominioById = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/`+id);
    return res.data;
}

const carregaDominiosPorCliente = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/byCliente/`+id);
    return res.data;
}

const upsert = async (obj, id) => {
    let res;
    if (id) {
        res = await axios.put(`${API_URL}/${route}/`+id, obj);
    } else {
        res = await axios.post(`${API_URL}/${route}/`, obj);
    }
    return res.data;
}

export { carregaDominios, upsert, carregaDominioById, carregaDominiosPorCliente };