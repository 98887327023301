import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {

    return (
        <div className="Header">
            <div className="Container">
                <div className="row">
                    <Link to="/"><img src="/assets/img/logo.png" alt="logo" /></Link>
                    <div className='Font-xlarge'>Painel administrativo</div>
                </div>
            </div>
        </div>
    )
}