import { useCallback, useEffect, useState } from "react";
import './ModuloSelect.css';

export default function ModuloSelect(props) {

    const [modulo, setModulo] = useState([]);

    const setModuloSelect = useCallback(async () => {
        const modulos = [
            {
                rota: "usuarios",
                label: "Usuários"
            },
            {
                rota: "grupos",
                label: "Grupos"
            },
            {
                rota: "clientes",
                label: "Clientes"
            },
            {
                rota: "cobrancas",
                label: "Cobranças"
            },

        ];

        setModulo(modulos);
    }, []);

    useEffect(() => {
        setModuloSelect();
    }, [setModuloSelect]);

    const renderOptions = () => {
        return modulo.map((ele, index) => {
            return (
                <option key={index} value={ele.rota}>{ele.label}</option>
            )
        })
    }

    return (
        <div className="ModuloSelect">
            <select name="rota" value={props.value} onChange={props.onchange} ref={props.reference({ required: true })}>
                {renderOptions()}
            </select>
        </div>
    )
}