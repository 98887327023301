import { useHistory } from 'react-router';
import { unsetToken } from '../../../services/auth';
import './Exit.css';

export default function Exit() {

    const history = useHistory();

    const logout = () => {
        unsetToken();
        history.push("/");
    }

    return (
        <button className="Exit Btn-cancel Btn" onClick={() => logout()}>
            Sair
        </button>
    )
}