import { faBriefcase, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { hasPermission } from "../../utils/utils";
import "./MenuBar.css";

export default function MenuBar() {

    const renderMenuItem = (rota, label, icon) => {
        const podeExibir = hasPermission(rota, 'visualizar');
        if (podeExibir) {
            return (
                <Link to={`/${rota}`}>
                    <FontAwesomeIcon icon={icon} />&nbsp;{label}
                </Link>
            )
        }
    }

    return (
        <div className="MenuBar">
            <div className="Container">
                <div className="nav">
                    {renderMenuItem('usuarios', 'Usuários', faUser)}
                    {renderMenuItem('grupos', 'Grupos', faUsers)}
                    {renderMenuItem('clientes', 'Clientes', faBriefcase)}
                    {renderMenuItem('cobrancas', 'Cobranças', faWallet)}
                </div>
            </div>
        </div>
    )
};