import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { carregaCobrancas, carregaCobrancasComFiltro, carregaCobrancasFiltroFixo, updatePayment } from "../../../../services/cobrancas";
import { converteDataToBrasil, converteValorToBrasil } from "../../../../utils/converts";
import { hasPermission, isDate, maskDate } from "../../../../utils/utils";
import "./CobrancasCrud.css";

export default function CobrancasCrud() {

    const { register, handleSubmit } = useForm();
    const [cobrancas, setCobrancas] = useState([]);

    const carregarCobrancas = useCallback(async () => {
        const res = await carregaCobrancas();
        setCobrancas(res);
    }, []);

    useEffect(() => {
        carregarCobrancas();
    }, [carregarCobrancas]);

    const baixaNoPagamento = async id => {
        if (window.confirm("Tem certeza que deseja realizar o pagamento?")) {
            await updatePayment(id);
        }
        carregarCobrancas();
    };

    const onSubmit = async (obj) => {
        const res = await carregaCobrancasComFiltro(obj);
        setCobrancas(res)
    };

    const filtraPorDias = async dias => {
        const dataAtual = new Date()
        const novaData = new Date()
        novaData.setDate(dataAtual.getDate() + dias)
        const res = await carregaCobrancasFiltroFixo(novaData);
        setCobrancas(res);
    };

    const renderItens = () => {
        if (cobrancas.length > 0) {
            return cobrancas.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.cliente.razaoSocial}</td>
                        <td>{converteDataToBrasil(ele.dataInicio)}</td>
                        <td>{ele.numeroParcelas}</td>
                        <td>{ele.parcelaAtual}</td>
                        <td>{converteValorToBrasil(ele.valorTotal)}</td>
                        <td>{converteDataToBrasil(ele.proximoPagamento)}</td>
                        <td>{ele.servicoVendido}</td>
                        <td>{ele.tipoServico}</td>
                        <td className="actions">
                            {hasPermission('cobrancas', 'editar') && <button onClick={() => { baixaNoPagamento(ele._id) }}>Boleto emitido</button>}
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    return (
        <div className="CobrancasCrud">
            <div className="row">
                <div className="search-buttons">
                    <button className="Btn Btn-confirm" type="button" onClick={() => { filtraPorDias(15) }}>15 dias</button>
                    <button className="Btn Btn-confirm" type="button" onClick={() => { filtraPorDias(30) }}>30 dias</button>
                    <button className="Btn Btn-confirm" type="button" onClick={() => { filtraPorDias(60) }}>60 dias</button>
                </div>
            </div>
            <div className="row">
                <div className="search">
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="proximoPagamento">Data até:</label>
                        <input type="text" name="proximoPagamento" onKeyUp={maskDate} maxLength={10} ref={register({ required: true, validate: { isDate: value => isDate(value) } })} />
                        <label htmlFor="TipoServico">Tipo:</label>
                        <select name="tipoServico" ref={register({ required: true })}>
                            <option value="Todos">Todos</option>
                            <option value="Dev. Website">Dev. Website</option>
                            <option value="Marketing Digital">Marketing Digital</option>
                        </select>
                        <button className="Btn Btn-confirm" type="submit">Pesquisar</button>
                        <button className="Btn Btn-confirm" type="button" onClick={() => { carregarCobrancas() }}>Limpar filtro</button>
                    </form>
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Cliente</td>
                            <td>Data de início</td>
                            <td>Núm. parcelas</td>
                            <td>Parcela atual</td>
                            <td>Valor total</td>
                            <td>Próximo pagto.</td>
                            <td>Serviço vendido</td>
                            <td>Tipo</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}