import { useState } from "react";
import { Link } from "react-router-dom";
import Exit from "../../components/Button/Exit/Exit";
import { decodeToken, getToken } from "../../services/auth";
import { hasPermission } from "../../utils/utils";
import "./Home.css";

export default function Home() {

    const [tokenState] = useState(getToken());
    const decoded = decodeToken(tokenState);

    const renderMenuItem = (rota, label) => {
        const podeExibir = hasPermission(rota);
        if (podeExibir) {
            return (
                <Link to={`/${rota}`}>
                    <div className="card">{label}</div>
                </Link>
            )
        }
    }

    return (
        <div className="Home">
            <div className="Container">
                <div className="Content">
                    <div className="row-title">
                        <div className="welcome">Bem vindo {decoded.nome}!</div>
                        <div className="logout-button">
                            <Exit />
                        </div>
                    </div>
                    <div className="row">
                        {renderMenuItem('usuarios', 'Usuários')}
                        {renderMenuItem('grupos', 'Grupos')}
                        {renderMenuItem('clientes', 'Clientes')}
                        {renderMenuItem('cobrancas', 'Cobranças')}
                    </div>
                    <div className="row-links">
                        <a href='https://tasks.office.com/awdigital.com.br/pt-BR/Home/Planner/#/userboard' target="_blank" rel='noreferrer noopener'><img src='/assets/img/planner.png' alt='icon'></img></a>
                        <a href='https://awsolucoes.sharepoint.com/sites/ClientesAW/Documentos%20Compartilhados/Forms/AllItems.aspx?viewid=8e0c6ff8%2Dc4d0%2D45bd%2Da534%2D45e1e3d23d84&id=%2Fsites%2FClientesAW%2FDocumentos%20Compartilhados%2FAW%20Digital' target="_blank" rel='noreferrer noopener'><img src='/assets/img/sharepoint.png' alt='icon'></img></a>
                        <a href='https://www.microsoft.com/pt-br/microsoft-teams/group-chat-software' target="_blank" rel='noreferrer noopener'><img src='/assets/img/teams.png' alt='icon'></img></a>
                        <a href='https://app.mlabs.com.br/' target="_blank" rel='noreferrer noopener'><img src='/assets/img/mlabs.png' alt='icon'></img></a>
                        <a href='https://ads.google.com/aw/overview?ocid=79700665&euid=223512870&__u=4127776630&uscid=79700665&__c=5098689585&authuser=0&subid=ALL-pt-BR-et-g-aw-c-home-awhp_xin1_signin!o2' target="_blank" rel='noreferrer noopener'><img src='/assets/img/ads.png' alt='icon'></img></a>
                        <a href='https://analytics.google.com/' target="_blank" rel='noreferrer noopener'><img src='/assets/img/analytics.png' alt='icon'></img></a>
                        <a href='https://app.contaazul.com/#/visao-geral' target="_blank" rel='noreferrer noopener'><img src='/assets/img/ca.png' alt='icon'></img></a>
                        <a href='https://web.agendor.com.br/sistema/negocios/funil.php' target="_blank" rel='noreferrer noopener'><img src='/assets/img/agendor.jpg' alt='icon'></img></a>
                    </div>
                </div>
            </div>
        </div >
    )
};