import axios from 'axios';
import { API_URL } from './../config/constants';
const route = 'grupos';

const updateActive = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/update-status/` +id);
    return res.data;
}

const carregaGrupos = async () => {
    const res = await axios.get(`${API_URL}/${route}/`);
    return res.data;
}

const carregaGruposAtivos = async () => {
    const res = await axios.get(`${API_URL}/${route}/active`);
    return res.data;
}

const carregaGrupoById = async (id) => {
    const res = await axios.get(`${API_URL}/${route}/`+id);
    return res.data;
}

const carregaGruposComFiltro = async (filter) => {
    const res = await axios.get(`${API_URL}/${route}?search=${filter}`);
    return res.data;
}

const upsert = async (obj, id) => {
    let res;
    if (id) {
        res = await axios.put(`${API_URL}/${route}/`+id, obj);
    } else {
        res = await axios.post(`${API_URL}/${route}/`, obj);
    }
    return res.data;
}

export { carregaGrupos, upsert, updateActive, carregaGrupoById, carregaGruposAtivos, carregaGruposComFiltro };