import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";
import ModalCobrancas from '../../../Modal/ModalCobrancas/ModalCobrancas';
import { upsert } from '../../../../services/cobrancas';
import { converteDataToBrasil, converteValorToBrasil } from '../../../../utils/converts';

import "./CobrancasCrud.css";
import { hasPermission } from '../../../../utils/utils';

export default function CobrancasCrud(props) {

    const [showModal, setShowModal] = useState(false);
    const [cobrancas, setCobrancas] = useState({});

    const editElement = (ele) => {
        setCobrancas(ele)
        openModal();
    };

    const openModal = () => {
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const onChangeCobrancas = (e) => {
        const _cobrancas = { ...cobrancas };
        _cobrancas.tipoServico = e.target.value;
        setCobrancas(_cobrancas);
    }

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        props.refresh(props.clienteId);
    };


    const renderItens = () => {
        if (props.dados.length > 0) {
            if (hasPermission('cobrancas', 'visualizar')) {
                return props.dados.map((ele, index) => {
                    return (
                        <tr key={index}>
                            <td className='td-dataInicio'>{converteDataToBrasil(ele.dataInicio)}</td>
                            <td>{ele.numeroParcelas}</td>
                            {ele.parcelaAtual > ele.numeroParcelas ? <td>{`${ele.parcelaAtual} - PAGO`}</td> : <td>{ele.parcelaAtual}</td>}
                            <td>{converteValorToBrasil(ele.valorTotal)}</td>
                            <td>{converteDataToBrasil(ele.proximoPagamento)}</td>
                            <td>{ele.servicoVendido}</td>
                            <td>{ele.tipoServico}</td>
                            <td className="actions">
                                {hasPermission('cobrancas', 'editar') && <button onClick={() => { editElement(ele) }}><FontAwesomeIcon icon={faEdit} /></button>}
                                {hasPermission('cobrancas', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                            </td>
                        </tr>
                    )
                })
            } else {
                return (
                    <tr>
                        <td>Para mais informações contate o setor financeiro</td>
                    </tr>
                )
            }
        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    };

    return (
        <div className="CobrancasSubCrud">
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Data de início</td>
                            <td>Núm. parcelas</td>
                            <td>Parcela atual</td>
                            <td>Valor total</td>
                            <td>Próximo pagto.</td>
                            <td>Serviço vendido</td>
                            <td>Tipo</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
                {showModal && <ModalCobrancas close={closeModal} clienteId={props.clienteId} value={cobrancas} refresh={props.refresh} onchange={onChangeCobrancas} />}
            </div>
        </div>
    )
};