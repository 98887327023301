import "./Footer.css";

export default function Footer() {

    const year = new Date().getFullYear()

    return (
        <div className="Footer">
            © {year} AW Digital - Painel administrativo - Todos os direitos reservados
        </div>
    )
}