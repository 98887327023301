import { getPermissions } from "../services/auth";

const isDate = str => {
    if (str !== '') {
        const arr = str.split("/");
        if (arr.length === 3) {
            if (
                (arr[0].length === 2 && parseInt(arr[0]) >= 1 && parseInt(arr[0]) <= 31)
                &&
                (arr[1].length === 2 && parseInt(arr[1]) >= 1 && parseInt(arr[1]) <= 12)
                &&
                (arr[2].length === 4 && parseInt(arr[2]) >= 1800 && parseInt(arr[2]) <= 9999)
            ) {
                return true;
            }
        }
        return false;
    }
};

const maskDate = e => {

    const pattern = /[^0-9]/gi;
    let value = e.target.value.replace(pattern, "");
    if (e.key !== 'Backspace') {

        const dateArr = [];
        for (let x = 1; x <= value.length; x++) {
            dateArr.push(value[x - 1]);
            if (x % 2 === 0 && x < 5) {
                dateArr.push("/");
            }
        }
        e.target.value = dateArr.join("");
    }
};

const hasPermission = (rota, acao='visualizar') => {
    const permissoes = getPermissions();
    const comparaPermissao = permissoes.find(obj => {
        if(obj.rota === rota && obj[acao]){
            return true;
        } else {
            return false
        }
    });
    return comparaPermissao;
}

export { isDate, maskDate, hasPermission };