import axios from 'axios';
import { verify } from 'jsonwebtoken';
import { API_URL, ID_TOKEN, TOKEN_SECRET, PERMISSION_TOKEN } from '../config/constants';

const decodeToken = token => {
    try {
        const decoded = verify(token, TOKEN_SECRET);
        return decoded;
    } catch (e) {
        if (e.message === 'jwt expired') {
            localStorage.removeItem(ID_TOKEN);
        }
        return null
    }
}

const getToken = () => {
    return localStorage.getItem(ID_TOKEN);
}

const setToken = (token) => {
    return localStorage.setItem(ID_TOKEN, token);
}

const unsetToken = () => {
    localStorage.removeItem(PERMISSION_TOKEN);
    return localStorage.removeItem(ID_TOKEN);
}

const isLogged = () => {
    return getToken();
}

const setPermissions = (permissoes) => {
    return localStorage.setItem(PERMISSION_TOKEN, JSON.stringify(permissoes));
}

const getPermissions = () => {
    if(localStorage.getItem(PERMISSION_TOKEN)){
        return JSON.parse(localStorage.getItem(PERMISSION_TOKEN));
    } else {
        return null
    }
}

const login = async (data) => {
    try {
        const res = await axios.post(`${API_URL}/usuarios/auth`, data);
        if (res.data.erro) {
            alert(res.data.msg);
        } else {
            setToken(res.data.token);
            setPermissions(res.data.permissoes)
        }
        return !res.data.erro;
    } catch {
        alert('Não foi possível conectar-se com o servidor.')
    }
}

export { decodeToken, isLogged, getToken, setToken, unsetToken, login, setPermissions, getPermissions };