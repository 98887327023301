import { useForm } from 'react-hook-form';
import { upsert } from '../../../services/permissaoGrupo';
import ModuloSelect from '../../Select/ModuloSelect/ModuloSelect';
import './ModalPermissaoGrupos.css';

export default function ModalPermissaoGrupos(props) {

    const { register, handleSubmit, errors } = useForm();

    const cancelar = () => {
        props.close();
    }

    const save = (data) => {
        if (props.value) {
            editData(data);
        } else {
            addData(data);
        }
    };

    const addData = async data => {
        data["grupo"] = props.grupoId;
        const res = await upsert(data);
        if (!res.erro) {
            props.refresh(props.grupoId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    const editData = async data => {
        data["grupo"] = props.value.grupo;
        const res = await upsert(data, props.value._id);
        if (!res.erro) {
            props.refresh(props.value.grupo._id);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    return (
        <div className="ModalPermissaoGrupos">
            <div className="container-modal">
                <form className='form-modal' onSubmit={handleSubmit(save)}>
                    <div className="title-modal Font-large">
                        Insira os dados de permissão
                    </div>
                    <div className="fields-modal">
                        <div className="field-modal">
                            <label htmlFor="rota">Módulo</label>
                            <ModuloSelect reference={register} value={props.value?.rota} onchange={props.onchange}/>
                            {/* <input className={errors.rota && "erroCampo"} type="text" name="rota" ref={register({ required: true })} />  */}
                            {/* defaultValue={props.value?.rota}  */}
                        </div>

                        <div className="field-modal checkbox">
                            <label htmlFor="visualizar" className="label">Visualizar</label>
                            <input className={errors.visualizar && "erroCampo"} type="checkbox" name="visualizar" id="checkbox" defaultChecked={props.value?.visualizar} ref={register()}/>
                        </div>
                        <div className="field-modal checkbox">
                            <label htmlFor="editar" className="label">Editar</label>
                            <input className={errors.editar && "erroCampo"} type="checkbox" name="editar" id="checkbox" defaultChecked={props.value?.editar} ref={register()}/>
                        </div>
                        <div className="field-modal checkbox">
                            <label htmlFor="criar" className="label">Criar</label>
                            <input className={errors.criar && "erroCampo"} type="checkbox" name="criar" id="checkbox" defaultChecked={props.value?.criar} ref={register()}/>
                        </div>
                        <div className="field-modal checkbox">
                            <label htmlFor="deletar" className="label">Deletar</label>
                            <input className={errors.deletar && "erroCampo"} type="checkbox" name="deletar" id="checkbox" defaultChecked={props.value?.deletar} ref={register()}/>
                        </div>
                    </div>
                    <div className="buttons-modal">
                        <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                        <button className="Btn Btn-cancel" onClick={cancelar}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}