import { useCallback, useEffect, useState } from "react";
import './TipoServico.css';

export default function TipoServico(props) {

    const [servico, setServico] = useState([]);

    const setTipoServico = useCallback(async () => {
        const tipos = [
            {
                indice: 0,
                servico: "Dev. Website", 
            },
            {
                indice: 1,
                servico: "Marketing Digital",
            },
            
        ];

        setServico(tipos);
    }, []);

    useEffect(() => {
        setTipoServico();
    }, [setTipoServico]);

    const renderOptions = () => {
        return servico.map((ele, index) => {
            return (
                <option
                    key={index}
                    value={ele.servico}
                >{ele.servico}</option>
            )
        })
    }

    return (
        <div className="TipoServico">
            <select
                value={props.value}
                name="tipoServico"
                onChange={props.onchange}
                ref={props.reference({ required: true })}
            >
                {renderOptions()}
            </select>
        </div>
    )
}