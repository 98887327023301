import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import SubCrudGrupos from "../../components/Crud/SubCrud/GruposCrud/GruposCrud";
import MenuBar from '../../components/Menu/MenuBar';
import ModalPermissaoGrupos from '../../components/Modal/ModalPermissaoGrupos/ModalPermissaoGrupos';
import Title from '../../components/Title/Title';
import { carregaGrupoById, upsert } from '../../services/grupos';
import { carregaPermissaoPorGrupo } from '../../services/permissaoGrupo';
import { hasPermission } from '../../utils/utils';
import './GruposForm.css';

export default function GruposForm() {

    const params = useParams();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [grupos, setGrupos] = useState({});

    const [permissoes, setPermissoes] = useState([]);

    const [showModalPermissao, setShowModalPermissao] = useState(false);

    if(!hasPermission('grupos', 'editar')){
        history.push('/');
    }

    const carregarGrupos = useCallback(async (id) => {
        const res = await carregaGrupoById(id);
        setGrupos(res);

        const resPermissoes = await carregaPermissaoPorGrupo(id);
        setPermissoes(resPermissoes);
    }, []);

    useEffect(() => {
        if (params.id) {
            carregarGrupos(params.id);
        }
    }, [carregarGrupos, params.id]);

    const onSubmit = obj => {
        save(obj, grupos._id);
    };

    const save = async (obj, id) => {
        const res = await upsert(obj, id);
        if (!res.erro) {
            history.push(`/grupos`);
        } else {
            alert(res.msg);
        }
    };

    const openModalPermissaoGrupo = () => {
        setShowModalPermissao(true)
    }

    const closeModalPermissaoGrupo = () => {
        setShowModalPermissao(false)
    }

    if (params.id) {
        return (
            <div className='GruposForm'>
                <MenuBar />
                <Title>Editar Grupos</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do grupo
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="nome">Nome do grupo</label>
                                        <input className={errors.nome && "erroCampo"} type="text" name="nome" defaultValue={grupos.nome} ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/grupos`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="form">
                                <div className="title Font-large">
                                    Permissão do grupo
                                </div>
                                <SubCrudGrupos dados={permissoes} grupoId={params.id} refresh={carregarGrupos}/>
                                {showModalPermissao && <ModalPermissaoGrupos close={closeModalPermissaoGrupo} grupoId={params.id} refresh={carregarGrupos}/>}
                                <div className="buttons">
                                    {hasPermission('grupos', 'criar') && <button className="Btn Btn-confirm" onClick={() => openModalPermissaoGrupo()}>Adicionar</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='GruposForm'>
                <MenuBar />
                <Title>Adicionar grupos</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do grupo
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="nome">Nome do grupo</label>
                                        <input className={errors.nome && "erroCampo"} type="text" name="nome" ref={register({ required: true })} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/grupos`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};