import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { upsert } from '../../../../services/permissaoGrupo';
import { hasPermission } from '../../../../utils/utils';
import ModalPermissaoGrupos from '../../../Modal/ModalPermissaoGrupos/ModalPermissaoGrupos';
import './GruposCrud.css';

export default function GruposCrud(props) {

    const [showModal, setShowModal] = useState(false);
    const [permissoes, setPermissoes] = useState({});

    const editElement = (ele) => {
        setPermissoes(ele)
        openModal();
    };

    const openModal = () => {
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const onChangeModulos = (e) => {
        const _permissoes = { ...permissoes };
        _permissoes.rota = e.target.value;
        setPermissoes(_permissoes);
    }

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        props.refresh(props.grupoId)
    };

    const renderItens = () => {
        if (props.dados.length > 0) {
            return props.dados.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.rota}</td>
                        <td>{ele.visualizar ? 'Sim' : 'Não'}</td>
                        <td>{ele.editar ? 'Sim' : 'Não'}</td>
                        <td>{ele.criar ? 'Sim' : 'Não'}</td>
                        <td>{ele.deletar ? 'Sim' : 'Não'}</td>
                        <td className="actions">
                            {hasPermission('grupos', 'editar') && <button onClick={() => { editElement(ele) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('grupos', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    };

    return (
        <div className="GruposSubCrud">
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Módulo</td>
                            <td>Visualizar</td>
                            <td>Editar</td>
                            <td>Criar</td>
                            <td>Deletar</td>
                            <td>Ação</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
                {showModal && <ModalPermissaoGrupos close={closeModal} value={permissoes} refresh={props.refresh} onchange={onChangeModulos} />}
            </div>
        </div>
    )
};