import { useForm } from 'react-hook-form';
import { upsert } from '../../../services/cobrancas';
import { converteDataToBrasil, converteValorToBrasil } from '../../../utils/converts';
import { isDate, maskDate } from '../../../utils/utils';
import TipoServico from '../../Select/TipoServicoSelect/TipoServico';
import './ModalCobrancas.css';

export default function ModalCobrancas(props) {

    const { register, handleSubmit, errors } = useForm();
    const cancelar = () => {
        props.close();
    }

    const save = (data) => {
        if (props.value) {
            editData(data);
        } else {
            addData(data);
        }
    };

    const addData = async data => {
        data["cliente"] = props.clienteId;
        const res = await upsert(data);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    const editData = async data => {
        data["cliente"] = props.value.cliente._id;
        const res = await upsert(data, props.value._id);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    return (
        <div className="ModalCobrancas">
            <div className="container-modal">
                <form className='form-modal' onSubmit={handleSubmit(save)}>
                    <div className="title-modal Font-large">
                        Insira os dados de cobrança
                    </div>
                    <div className="fields-modal">
                        <div className="field-modal">
                            <label htmlFor="dataInicio">Data de início</label>
                            <input className={errors.dataInicio && "erroCampo"} type="text" name="dataInicio" defaultValue={converteDataToBrasil(props.value?.dataInicio)} onKeyUp={maskDate} maxLength={10} ref={register({ required: true, validate: { isDate: value => isDate(value) } })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="numeroParcelas">Número de parcelas</label>
                            <input className={errors.numeroParcelas && "erroCampo"} type="text" name="numeroParcelas" defaultValue={props.value?.numeroParcelas} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="parcelaAtual">Parcela atual</label>
                            <input className={errors.parcelaAtual && "erroCampo"} type="text" name="parcelaAtual" defaultValue={props.value?.parcelaAtual} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="valorTotal">Valor total</label>
                            <input type="text" className={errors.valorTotal && "erroCampo"} name="valorTotal" defaultValue={converteValorToBrasil(props.value?.valorTotal)} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="proximoPagamento">Próximo pagamento</label>
                            <input className={errors.proximoPagamento && "erroCampo"} type="text" name="proximoPagamento" defaultValue={converteDataToBrasil(props.value?.proximoPagamento)} onKeyUp={maskDate} maxLength={10} ref={register({ required: true, validate: { isDate: value => isDate(value) } })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="tipoServico">Tipo de serviço</label>
                            <TipoServico reference={register} value={props.value?.tipoServico} onchange={props.onchange} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="servicoVendido">Serviço vendido</label>
                            <input type="text" name="servicoVendido" defaultValue={props.value?.servicoVendido} ref={register()} />
                        </div>
                    </div>
                    <div className="buttons-modal">
                        <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                        <button className="Btn Btn-cancel" onClick={cancelar}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}