import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import MenuBar from '../../components/Menu/MenuBar';
import Title from '../../components/Title/Title';

import ContatosCrud from '../../components/Crud/SubCrud/ContatosCrud/ContatosCrud'
import EnderecosCrud from '../../components/Crud/SubCrud/EnderecosCrud/EnderecosCrud';
import DominiosCrud from '../../components/Crud/SubCrud/DominiosCrud/DominiosCrud';
import CobrancasCrud from '../../components/Crud/SubCrud/CobrancasCrud/CobrancasCrud';

import ModalContatos from '../../components/Modal/ModalContatos/ModalContatos';
import ModalEnderecos from '../../components/Modal/ModalEnderecos/ModalEnderecos';
import ModalDominios from '../../components/Modal/ModalDominios/ModalDominios';
import ModalCobrancas from '../../components/Modal/ModalCobrancas/ModalCobrancas';

import { carregaClienteById, upsert } from '../../services/clientes';
import { carregaContatosPorCliente } from '../../services/contatos';
import { carregaEnderecosPorCliente } from '../../services/enderecos';
import { carregaDominiosPorCliente } from '../../services/dominios';
import { carregaCobrancasPorCliente } from '../../services/cobrancas';

import './ClientesForm.css';
import { hasPermission } from '../../utils/utils';

export default function ClientesForm() {

    const params = useParams();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [clientes, setClientes] = useState({});

    const [contatos, setContatos] = useState([]);
    const [enderecos, setEnderecos] = useState([]);
    const [dominios, setDominios] = useState([]);
    const [cobrancas, setCobrancas] = useState([]);

    const [showModalContatos, setShowModalContatos] = useState(false);
    const [showModalEnderecos, setShowModalEnderecos] = useState(false);
    const [showModalDominios, setShowModalDominios] = useState(false);
    const [showModalCobrancas, setShowModalCobrancas] = useState(false);

    if(!hasPermission('clientes','editar')){
        history.push('/');
    }

    const carregarDados = useCallback(async (id) => {
        const res = await carregaClienteById(id);
        setClientes(res);

        const resContato = await carregaContatosPorCliente(id);
        setContatos(resContato);

        const resEndereco = await carregaEnderecosPorCliente(id);
        setEnderecos(resEndereco);

        const resDominio = await carregaDominiosPorCliente(id);
        setDominios(resDominio);

        const resCobranca = await carregaCobrancasPorCliente(id);
        setCobrancas(resCobranca);
    }, []);

    useEffect(() => {
        if (params.id) {
            carregarDados(params.id);
        }
    }, [carregarDados, params.id]);

    const onSubmit = obj => {
        save(obj, clientes._id);
    };

    const save = async (obj, id) => {
        const res = await upsert(obj, id);
        if (!res.erro) {
            history.push(`/clientes`);
        } else {
            alert(res.msg);
        }
    };

    const openModalContato = () => {
        setShowModalContatos(true);
    };

    const closeModalContato = () => {
        setShowModalContatos(false);
    };

    const openModalEndereco = () => {
        setShowModalEnderecos(true);
    };

    const closeModalEndereco = () => {
        setShowModalEnderecos(false);
    };

    const openModalDominio = () => {
        setShowModalDominios(true);
    };

    const closeModalDominio = () => {
        setShowModalDominios(false);
    };

    const openModalCobranca = () => {
        if(clientes.active === true){
            setShowModalCobrancas(true);
        } else {
            alert('Cliente está bloqueado/inativo e não é possível adicionar novas cobranças')
        }
    };

    const closeModalCobranca = () => {
        setShowModalCobrancas(false);
    };

    if (params.id) {
        return (
            <div className='ClientesForm'>
                <MenuBar />
                <Title>Editar Clientes</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do cliente
                                </div>
                                <div className="buttons">
                                    <a className="Btn btn-consulta" href={"http://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp?cnpj="+clientes.cnpj} target="_blank" rel="noreferrer noopener">Consulta CNPJ</a>
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="razaoSocial">Razão social</label>
                                        <input className={errors.razaoSocial && "erroCampo"} type="text" name="razaoSocial" defaultValue={clientes.razaoSocial} ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="nomeFantasia">Nome Fantasia</label>
                                        <input type="text" name="nomeFantasia" defaultValue={clientes.nomeFantasia} ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="cnpj">CNPJ</label>
                                        <input className={errors.cnpj && "erroCampo"} type="text" name="cnpj" defaultValue={clientes.cnpj} ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inscricaoEstadual">Inscrição estadual</label>
                                        <input type="text" name="inscricaoEstadual" defaultValue={clientes.inscricaoEstadual} ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="responsavel">Responsável</label>
                                        <input type="text" name="responsavel" defaultValue={clientes.responsavel} ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="cpf">CPF</label>
                                        <input type="text" name="cpf" defaultValue={clientes.cpf} ref={register()} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/clientes`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="form">
                                <div className="title Font-large">
                                    Dados de contato
                                </div>
                                <ContatosCrud dados={contatos} clienteId={params.id} refresh={carregarDados} />
                                {showModalContatos && <ModalContatos close={closeModalContato} clienteId={params.id} refresh={carregarDados} />}
                                <div className="buttons">
                                   {hasPermission('clientes', 'criar') && <button className="Btn Btn-confirm" onClick={() => openModalContato()}>Adicionar</button>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form">
                                <div className="title Font-large">
                                    Dados de endereço
                                </div>
                                <EnderecosCrud dados={enderecos} clienteId={params.id} refresh={carregarDados} />
                                {showModalEnderecos && <ModalEnderecos close={closeModalEndereco} clienteId={params.id} refresh={carregarDados} />}
                                <div className="buttons">
                                    {hasPermission('clientes', 'criar') && <button className="Btn Btn-confirm" onClick={() => openModalEndereco()}>Adicionar</button>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form">
                                <div className="title Font-large">
                                    Dados de domínio
                                </div>
                                <DominiosCrud dados={dominios} clienteId={params.id} refresh={carregarDados} />
                                {showModalDominios && <ModalDominios close={closeModalDominio} clienteId={params.id} refresh={carregarDados} />}
                                <div className="buttons">
                                    {hasPermission('clientes', 'criar') && <button className="Btn Btn-confirm" onClick={() => openModalDominio()}>Adicionar</button>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form">
                                <div className="title Font-large">
                                    Dados de cobrança
                                </div>
                                <CobrancasCrud dados={cobrancas} clienteId={params.id} refresh={carregarDados} />
                                {showModalCobrancas && <ModalCobrancas close={closeModalCobranca} clienteId={params.id} refresh={carregarDados} reference={register} />}
                                <div className="buttons">
                                    {hasPermission('cobrancas', 'criar') && <button className="Btn Btn-confirm" onClick={() => openModalCobranca()}>Adicionar</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='ClientesForm'>
                <MenuBar />
                <Title>Adicionar clientes</Title>
                <div className="Container">
                    <div className="Content">
                        <div className="row">
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div className="title Font-large">
                                    Dados do cliente
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <label htmlFor="razaoSocial">Razão social</label>
                                        <input className={errors.razaoSocial && "erroCampo"} type="text" name="razaoSocial" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="nomeFantasia">Nome Fantasia</label>
                                        <input type="text" name="nomeFantasia" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="cnpj">CNPJ</label>
                                        <input className={errors.cnpj && "erroCampo"} type="text" name="cnpj" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inscricaoEstadual">Inscrição estadual</label>
                                        <input type="text" name="inscricaoEstadual" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="responsavel">Responsável</label>
                                        <input type="text" name="responsavel" ref={register()} />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="cpf">CPF</label>
                                        <input type="text" name="cpf" ref={register()} />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                                    <button className="Btn Btn-cancel" onClick={() => { history.push(`/clientes`) }}>Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};