import './Title.css';

export default function Title(props) {
    return (
        <div className="TitleBar">
            <div className="Container">
                <div className="content Font-large">
                    <div className="title">
                        {props.children}
                    </div>
                    <div className="links">
                        <a href='https://tasks.office.com/awdigital.com.br/pt-BR/Home/Planner/#/userboard' target="_blank" rel='noreferrer noopener'><img src='/assets/img/planner.png' alt='icon'></img></a>
                        <a href='https://awsolucoes.sharepoint.com/sites/ClientesAW/Documentos%20Compartilhados/Forms/AllItems.aspx?viewid=8e0c6ff8%2Dc4d0%2D45bd%2Da534%2D45e1e3d23d84&id=%2Fsites%2FClientesAW%2FDocumentos%20Compartilhados%2FAW%20Digital' target="_blank" rel='noreferrer noopener'><img src='/assets/img/sharepoint.png' alt='icon'></img></a>
                        <a href='https://www.microsoft.com/pt-br/microsoft-teams/group-chat-software' target="_blank" rel='noreferrer noopener'><img src='/assets/img/teams.png' alt='icon'></img></a>
                        <a href='https://app.mlabs.com.br/' target="_blank" rel='noreferrer noopener'><img src='/assets/img/mlabs.png' alt='icon'></img></a>
                        <a href='https://ads.google.com/aw/overview?ocid=79700665&euid=223512870&__u=4127776630&uscid=79700665&__c=5098689585&authuser=0&subid=ALL-pt-BR-et-g-aw-c-home-awhp_xin1_signin!o2' target="_blank" rel='noreferrer noopener'><img src='/assets/img/ads.png' alt='icon'></img></a>
                        <a href='https://analytics.google.com/' target="_blank" rel='noreferrer noopener'><img src='/assets/img/analytics.png' alt='icon'></img></a>
                        <a href='https://app.contaazul.com/#/visao-geral' target="_blank" rel='noreferrer noopener'><img src='/assets/img/ca.png' alt='icon'></img></a>
                        <a href='https://web.agendor.com.br/sistema/negocios/funil.php' target="_blank" rel='noreferrer noopener'><img src='/assets/img/agendor.jpg' alt='icon'></img></a>
                    </div>
                </div>
            </div>
        </div>
    )
}