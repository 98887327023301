import { useForm } from 'react-hook-form';
import { upsert } from '../../../services/dominios';
import './ModalDominios.css';

export default function ModalDominios(props) {

    const { register, handleSubmit, errors } = useForm();

    const cancelar = () => {
        props.close();
    }

    const save = (data) => {
        if (props.value) {
            editData(data);
        } else {
            addData(data);
        }
    };

    const addData = async data => {
        data["cliente"] = props.clienteId;
        const res = await upsert(data);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    const editData = async data => {
        data["cliente"] = props.value.cliente;
        const res = await upsert(data, props.value._id);
        if (!res.erro) {
            props.refresh(props.clienteId);
            props.close();
        } else {
            alert(res.msg);
        }
    }

    return (
        <div className="ModalDominios">
            <div className="container-modal">
                <form className='form-modal' onSubmit={handleSubmit(save)}>
                    <div className="title-modal Font-large">
                        Insira os dados de domínio
                    </div>
                    <div className="fields-modal">
                        <div className="field-modal">
                            <label htmlFor="descricao">Descrição</label>
                            <input className={errors.descricao && "erroCampo"} type="text" name="descricao" defaultValue={props.value?.descricao} ref={register({ required: true })} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="login">Login</label>
                            <input type="text" name="login" defaultValue={props.value?.login} ref={register()} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="senha">Senha</label>
                            <input type="text" name="senha" defaultValue={props.value?.senha} ref={register()} />
                        </div>
                        <div className="field-modal">
                            <label htmlFor="observacao">Observação</label>
                            <input type="text" name="observacao" defaultValue={props.value?.observacao} ref={register()} />
                        </div>
                    </div>
                    <div className="buttons-modal">
                        <button className="Btn Btn-confirm" type="submit">Confirmar</button>
                        <button className="Btn Btn-cancel" onClick={cancelar}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}