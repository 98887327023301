import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { decodeToken, getToken, login, setToken } from './../../services/auth';
import './Login.css';

export default function Login() {

    const { register, handleSubmit } = useForm();
    const [tokenState] = useState(getToken());
    const decoded = decodeToken(tokenState);
    const history = useHistory();

    if (decoded) {
        history.push('/');
    }

    const sendData = async data => {
        const logado = await login(data);
        if (logado) {
            setToken(getToken());
            history.push('/');
        }
    }

    return (
        <div className="Login">
            <div className="Container">
                <div className="Content">
                    <div className="box">
                        <h2>Login</h2>
                        <form onSubmit={handleSubmit(sendData)} className="login-form">
                            <div className="row">
                                <input name="login" type="text" className="login-input" ref={register({ required: true })} />
                                <label className="login-label">Login</label>
                            </div>
                            <div className="row">
                                <input name="senha" type="password" className="login-input" ref={register({ required: true })} />
                                <label className="login-label">Senha</label>
                            </div>
                            <div className="row">
                                <button type="submit" className="login-button">Entrar</button>
                            </div>
                        </form>
                        {/* <div className="options">
                    <Link to="/recuperar-senha">Esqueci minha senha</Link>
                </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}