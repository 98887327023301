import { useHistory } from 'react-router-dom';
import Exit from "../../components/Button/Exit/Exit";
import UsuariosCrud from "../../components/Crud/MainCrud/UsuariosCrud/UsuariosCrud";
import MenuBar from "../../components/Menu/MenuBar";
import Title from "../../components/Title/Title";
import { hasPermission } from "../../utils/utils";
import "./Usuarios.css";

export default function Usuarios() {

    const history = useHistory();

    if (!hasPermission('usuarios')) {
        history.push('/');
    }

    return (
        <div className='Usuarios'>
            <MenuBar />
            <Title>Usuários</Title>
            <div className="Container">
                <div className="Content">
                    <div className="row">
                        <UsuariosCrud />
                    </div>
                    <div className="Row-exit">
                        <Exit />
                    </div>
                </div>
            </div>
        </div>
    )
};