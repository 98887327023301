import { faEdit, faLock, faLockOpen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router";
import { carregaClientes, updateActive, upsert, carregaClientesComFiltro } from '../../../../services/clientes';
import { hasPermission } from "../../../../utils/utils";
import "./ClientesCrud.css";

export default function ClientesCrud(props) {

    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const [clientes, setClientes] = useState([]);

    const carregarClientes = useCallback(async () => {
        const res = await carregaClientes();
        setClientes(res);
    }, []);

    useEffect(() => {
        carregarClientes();
    }, [carregarClientes]);

    const editElement = (id) => {
        history.push(`/clientes/editar/${id}`);
    };

    const changeActive = async (id) => {
        await updateActive(id);
        carregarClientes();
    };

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        carregarClientes();
    };

    const onSubmit = obj => {
        const novoObj = Object.values(obj).toString()
        save(novoObj);
    };

    const save = async (obj) => {
        const res = await carregaClientesComFiltro(obj);
        setClientes(res)
    };

    const renderItens = () => {
        if (clientes.length > 0) {
            return clientes.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.razaoSocial}</td>
                        <td>{ele.nomeFantasia}</td>
                        <td>{ele.cnpj}</td>
                        <td>{ele.active === true ? "Ativo" : "Inativo"}</td>
                        <td className="actions">
                            {hasPermission('clientes', 'editar') && <button onClick={() => { editElement(ele._id) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('clientes', 'editar') && <button onClick={() => { changeActive(ele._id) }}><FontAwesomeIcon icon={ele.active === true ? faLock : faLockOpen} /></button>}
                            {hasPermission('clientes', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    return (
        <div className="ClientesCrud">
            <div className="row">
                <div className="search">
                <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="text" name="search" ref={register()} />
                        <button className="Btn Btn-confirm" type="submit">Pesquisar</button>
                        <button className="Btn Btn-confirm" type="button" onClick={()=>{carregarClientes()}}>Limpar filtro</button>
                    </form>
                </div>
                <div className="add">
                    {hasPermission('clientes', 'criar') && <button className="Btn Btn-confirm" onClick={() => history.push(`clientes/adicionar`)}>Adicionar</button>}
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Razão social</td>
                            <td>Nome fantasia</td>
                            <td className="td-cnpj">CNPJ</td>
                            <td>Status</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}