import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";
import ModalContatos from '../../../Modal/ModalContatos/ModalContatos';
import { upsert } from '../../../../services/contatos';

import "./ContatosCrud.css";
import { hasPermission } from '../../../../utils/utils';

export default function ContatosCrud(props) {

    const [showModal, setShowModal] = useState(false);
    const [contatos, setContatos] = useState({});

    const editElement = (ele) => {
        setContatos(ele)
        openModal();
    };

    const openModal = () => {
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const logicalDelete = async (id) => {
        if (window.confirm("Tem certeza que deseja deletar o registro?")) {
            const obj = { deleted: true };
            await upsert(obj, id);
        }
        props.refresh(props.clienteId);
    };

    const renderItens = () => {
        if (props.dados.length > 0) {
            return props.dados.map((ele, index) => {
                return (
                    <tr key={index}>
                        <td>{ele.nome}</td>
                        <td>{ele.cargo}</td>
                        <td>{ele.telefone}</td>
                        <td>{ele.email}</td>
                        <td>{ele.descricao}</td>
                        <td>{ele.contatoCobranca === true ? "Sim" : "Não"}</td>
                        <td className="actions">
                            {hasPermission('clientes', 'editar') && <button onClick={() => { editElement(ele) }}><FontAwesomeIcon icon={faEdit} /></button>}
                            {hasPermission('clientes', 'deletar') && <button onClick={() => { logicalDelete(ele._id) }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                <tr>
                    <td>Nenhum registro encontrado</td>
                </tr>
            )
        }
    };

    return (
        <div className="ContatosCrud">
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <td>Nome</td>
                            <td>Cargo</td>
                            <td>Telefone</td>
                            <td>E-mail</td>
                            <td>Descrição</td>
                            <td>Cobrança</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItens()}
                    </tbody>
                </table>
                {showModal && <ModalContatos close={closeModal} clienteId={props.clienteId} value={contatos} refresh={props.refresh} />}
            </div>
        </div>
    )
};